
import { defineComponent, ref } from 'vue';
import { Field } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Datatable from '@/components/kt-datatable/kTDatatableLeft.vue';
import ApiService from '@/core/services/ApiService';
import PhotoDrawer from '@/layout/header/partials/trainee/photoUpdateDrawer.vue';
import BankDrawer from '@/layout/header/partials/trainee/BankInfoDrawer.vue';
import LicenseDrawer from '@/layout/header/partials/trainee/LicenseInfoDrawer.vue';
import TraineeInfoEditDrawer from '@/layout/header/partials/trainee/TraineeInfoEditDrawer.vue';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
import moment from 'moment';
import TraineeEditModal from '@/layout/header/partials/trainee/TraineeEditDrawer.vue';
import { VueCookieNext } from 'vue-cookie-next';
import axios from 'axios';
import { ElNotification } from 'element-plus';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'trainee-update',
  components: {
    Field,
    Datatable,
    PhotoDrawer,
    BankDrawer,
    LicenseDrawer,
    TraineeInfoEditDrawer,
    TraineeEditModal,
  },
  data() {
    return {
      enrollfemaleCount: 0 as any,
      notenrollfemaleCount: 0 as any,

      moment: '' as any,
      showLicenceBtn: false,
      courseTypeId: '' as any,
      showEnrollButton: false,
      errorMessage: '',
      api_url: '',
      trainee: {
        entity_id: '',
        training_institute_id: '',
        course_info_id: '',
        tranche_id: '',
        batch_info_id: '',
      },
      batchInfo: {
        id: '',
        start_date: '',
        end_date: '',
        training_capacity: '',
      },
      notenrolltableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '150px',
        },
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Photo',
          key: 'photo',
          sortable: true,
        },
        {
          name: 'Name',
          key: 'name',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Reg No.',
          key: 'reg_no',
          sortable: true,
          width: '170px',
        },
        {
          name: 'Ref No.',
          key: 'ref_no',
          sortable: true,
          width: '170px',
        },
        {
          name: 'NID',
          key: 'nid',
          sortable: true,
        },
        {
          name: 'BCN',
          key: 'bcn',
          sortable: true,
          width: '100px',
        },
        {
          name: 'Gender',
          key: 'gender',
          sortable: true,
        },
        {
          name: 'Mobile',
          key: 'phone',
          sortable: true,
        },
        {
          name: 'Date of Birth',
          key: 'birth_date',
          sortable: true,
          width: '150px',
        },
      ],
      enrolltableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '150px',
        },
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Photo',
          key: 'photo',
          sortable: true,
        },
        {
          name: 'Trainee Name',
          key: 'name',
          sortable: true,
        },
        {
          name: 'Reg No.',
          key: 'reg_no',
          sortable: true,
        },
        {
          name: 'Ref No.',
          key: 'ref_no',
          sortable: true,
        },
        {
          name: 'Gender',
          key: 'gender',
          sortable: true,
        },
        {
          name: 'NID',
          key: 'nid',
          sortable: true,
        },
        {
          name: 'BCN',
          key: 'bcn',
          sortable: true,
          width: '100px',
        },
        {
          name: 'Mobile',
          key: 'mobile',
          sortable: true,
        },
        {
          name: 'Date of Birth',
          key: 'dob',
          sortable: true,
        },
        {
          name: 'Account Status',
          key: 'account_status',
          sortable: true,
        },
      ],
      associations: [],
      institutes: [],
      courses: [],
      tranches: [],
      batches: [],
      enrolleTraineeList: [],
      notenrollTraineeList: [],
      notEnComponentKey: 0,
      enComponentKey: 0,
      load: false,
      loading: false,
      courseInfoData: false,
      notEnrollShow: false,
      enrollShow: false,
      showtrainingProviderNotice: false,
      tabIndex: ref(0),
      btnCheck: false,
      enrollLoad: false,
    };
  },
  async created() {
    this.moment = moment;
    await this.associationList();
    await this.getTranches();
    this.emitter.on('infos-updated', async () => {
      await this.getTrainees();
    });
    this.emitter.on('trainee-updated', async () => {
      await this.getEnrolleTraineeList();
    });
    this.api_url = this.VUE_APP_API_URL;
  },
  methods: {
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      let en_status = 0;
      formData.set('entity_id', this.trainee.entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('training_institute_id', this.trainee.training_institute_id);
      formData.set('course_info_id', this.trainee.course_info_id);
      formData.set('batch_info_id', this.trainee.batch_info_id);
      formData.set('enrollment_status', '1');

      await ApiService.post('trainee/trainee_enrollment_list_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async printPdf() {
      let formData = new FormData();
      let en_status = 1;
      formData.set('entity_id', this.trainee.entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('training_institute_id', this.trainee.training_institute_id);
      formData.set('course_info_id', this.trainee.course_info_id);
      formData.set('batch_info_id', this.trainee.batch_info_id);
      formData.set('enrollment_status', '1');

      let data = `${this.VUE_APP_API_URL}/api/trainee/trainee_enrollment_list_print`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },

    async exportTrainee() {
      let formData = new FormData();
      let en_status = 1;
      formData.set('entity_id', this.trainee.entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('training_institute_id', this.trainee.training_institute_id);
      formData.set('course_info_id', this.trainee.course_info_id);
      formData.set('batch_info_id', this.trainee.batch_info_id);
      formData.set('enrollment_status', '1');
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/trainee/trainee_enrollment_list_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Enrollment_Trainee List.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async downloadPdfd() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      let en_status = 0;
      formData.set('entity_id', this.trainee.entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('training_institute_id', this.trainee.training_institute_id);
      formData.set('course_info_id', this.trainee.course_info_id);
      formData.set('batch_info_id', this.trainee.batch_info_id);
      formData.set('enrollment_status', '0');

      await ApiService.post('trainee/trainee_enrollment_list_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async printPdfd() {
      let formData = new FormData();
      let en_status = 0;
      formData.set('entity_id', this.trainee.entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('training_institute_id', this.trainee.training_institute_id);
      formData.set('course_info_id', this.trainee.course_info_id);
      formData.set('batch_info_id', this.trainee.batch_info_id);
      formData.set('enrollment_status', '0');

      let data = `${this.VUE_APP_API_URL}/api/trainee/trainee_enrollment_list_print`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },

    async exportTraineed() {
      let formData = new FormData();
      let en_status = 0;
      formData.set('entity_id', this.trainee.entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('training_institute_id', this.trainee.training_institute_id);
      formData.set('course_info_id', this.trainee.course_info_id);
      formData.set('batch_info_id', this.trainee.batch_info_id);
      formData.set('enrollment_status', '0');
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/trainee/trainee_enrollment_list_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Enrollment_Trainee List.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async btnCheckStatus() {
      if (this.trainee.batch_info_id) {
        this.btnCheck = true;
      }
    },
    async updatePhoto(data) {
      this.emitter.emit('photo_id_updated', data);
    },
    async updateBankInfo(data) {
      this.emitter.emit('bank_info_updated', data);
    },
    async updateLicenseInfo(data) {
      this.emitter.emit('license_info_updated', data);
    },
    async updateTraineeInfo(data) {
      this.emitter.emit('trainee_info_updated', data);
    },
    async associationList() {
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get(
        this.VUE_APP_GET_ENTITY_LIST_API + '?entity_id=' + entity_id
      )
        .then((response) => {
          this.associations = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async trancheChange() {
      this.trainee.course_info_id = '';
      this.trainee.entity_id = '';
      this.trainee.training_institute_id = '';
      this.trainee.batch_info_id = '';
      this.enrolleTraineeList = [];
      this.notenrollTraineeList = [];
      this.enrollShow = false;
      this.notEnrollShow = false;
    },

    async trainingInstitute() {
      this.load = true;
      this.trainee.course_info_id = '';
      this.trainee.training_institute_id = '';
      this.trainee.batch_info_id = '';
      this.enrolleTraineeList = [];
      this.notenrollTraineeList = [];
      this.enrollShow = false;
      this.notEnrollShow = false;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
        '?entity_id=' +
        this.trainee.entity_id +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          this.institutes = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseList() {
      this.load = true;
      this.trainee.course_info_id = '';
      this.trainee.batch_info_id = '';
      this.enrolleTraineeList = [];
      this.notenrollTraineeList = [];
      this.enrollShow = false;
      this.notEnrollShow = false;
      let entity_id = this.trainee.entity_id;
      let institute_info_id = this.trainee.training_institute_id;

      await ApiService.get(
        'course/list?entity_id=' +
        entity_id +
        '&tranche=' +
        this.trainee.tranche_id +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          this.courses = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getBatchList() {
      this.load = true;
      this.trainee.batch_info_id = '';
      this.enrolleTraineeList = [];
      this.notenrollTraineeList = [];
      this.enrollShow = false;
      this.notEnrollShow = false;
      let entity_id = this.trainee.entity_id;
      let institute_info_id = this.trainee.training_institute_id;
      await ApiService.get(
        'batch/list?entity_id=' +
        entity_id +
        '&course_info_id=' +
        this.trainee.course_info_id +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          this.batches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranches() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEnrolleTraineeList() {
      if (this.trainee.batch_info_id) {
        this.enrollLoad = true;
        let enr_status = 1;
        await ApiService.get(
          'trainee/enrollment_list_info?entity_id=' +
          this.trainee.entity_id +
          '&tranche_id=' +
          this.trainee.tranche_id +
          '&training_institute_id=' +
          this.trainee.training_institute_id +
          '&course_info_id=' +
          this.trainee.course_info_id +
          '&batch_info_id=' +
          this.trainee.batch_info_id +
          '&enrollment_status=' +
          enr_status
        )
          .then((response) => {
            this.showCourse(this.trainee.course_info_id);

            this.enrolleTraineeList = response.data.data.data;

            this.enrollfemaleCount = response.data.data.femaleCount;
            this.enrollShow = true;
            this.enComponentKey += 1;

            this.enrollLoad = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
    async getBatchInfo() {
      if (this.trainee.batch_info_id) {
        this.load = true;
        await ApiService.get('batch/show/' + this.trainee.batch_info_id)
          .then((response) => {
            this.load = false;
            this.batchInfo = response.data.data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
    async getNotEnrolleTraineeList() {
      if (this.trainee.batch_info_id) {
        this.load = true;
        let en_status = 0;
        await ApiService.get(
          'trainee/enrollment_list_info?entity_id=' +
          this.trainee.entity_id +
          '&tranche_id=' +
          this.trainee.tranche_id +
          '&training_institute_id=' +
          this.trainee.training_institute_id +
          '&course_info_id=' +
          this.trainee.course_info_id +
          '&batch_info_id=' +
          this.trainee.batch_info_id +
          '&enrollment_status=' +
          en_status
        )
          .then((response) => {
            this.load = false;
            this.notenrollTraineeList = response.data.data.data;
            this.notenrollfemaleCount = response.data.data.femaleCount;

            this.notEnComponentKey += 1;
            this.notEnrollShow = true;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
    getTrainees() {
      this.getBatchInfo();
      this.getEnrolleTraineeList();
      this.getNotEnrolleTraineeList();
      ApiService.get(
        'trainee/enroll_verify?batch_info_id=' + this.trainee.batch_info_id
      ).then((response) => {
        if (response.data.status == 'error') {
          this.showEnrollButton = false;
          this.errorMessage = 'Note: ' + response.data.message;
        } else {
          if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
            if (VueCookieNext.getCookie('_seip_role_id') == 6 || VueCookieNext.getCookie('_seip_role_id') == 39) {
              this.showEnrollButton = true;
            }
          } else {
            this.showEnrollButton = true;
          }
        }
      });
    },
    async showCourse(id) {
      await ApiService.get('course/show/' + id)
        .then((response) => {
          this.courseTypeId = response.data.data.course_sector;
          if (this.courseTypeId === 10) {
            this.showLicenceBtn = true;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    enrollApplicants() {
      this.loading = true;
      var data = {
        entity_id: this.trainee.entity_id,
        tranche_id: this.trainee.tranche_id,
        course_info_id: this.trainee.course_info_id,
        training_institute_id: this.trainee.training_institute_id,
        batch_info_id: this.trainee.batch_info_id,
        start_date: this.batchInfo.start_date,
        end_date: this.batchInfo.end_date,
        traineeList: this.notenrollTraineeList,
      };
      ApiService.post('trainee/enroll', data)
        .then((response) => {
          Swal.fire({
            title: response.data.status,
            html: response.data.data,
            icon: response.data.status,
          }).then((result) => {
            this.emitter.emit('infos-updated', true);
            this.loading = false;
          });
        })
        .catch(({ response }) => {
          Swal.fire(response.data.status, response.data.data, response.status);
          console.log(response);
        });
    },
    setActiveTab(event) {
      this.tabIndex = parseInt(event.target.getAttribute('data-tab-index'));
    },
    Delete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('trainee/delete/' + `${id}`)
            .then((response) => {
              if ((response.data.status = 'error')) {
                Swal.fire({
                  title: 'Error!',
                  html: response.data.message,
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Close',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                });
              } else {
                Swal.fire({
                  title: 'Deleted!',
                  text: response.data.data,
                  icon: 'success',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => {
                  this.emitter.emit('trainee-updated', true);
                });
              }
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    edit(data) {
      this.emitter.emit('trainee-edit', data);
    },
    activateTrainningPartner(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to Activate it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, activate!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('institute/detail/status_change/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('trainning-updated', true);
              Swal.fire('Activated!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
