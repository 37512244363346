
//import ApiService from "@/core/services/ApiService";
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";
export default defineComponent({
  name: "trainee_bank_info",
  components: {
    Field,
  },
  data() {
    return {
      bankType: false,
      api_url: process.env.VUE_APP_API_URL,
      formData: {
        id: "",
        account_type: "",
        bank_name: "",
        x_mobile_bankings_id: "",
        x_agent_banking_id: "",
        bank_branch_name: "",
        bank_routing_no: "",
        bank_account_number: "",
        confirmaccount_no: "",
      },
      url: "",
      loading: false,
      banks: [],
      mobile_banks: [],
      agent_banks: [],
      entity_type: "" as any,
      user_role: "",
      userInfo: {
          employee: {
            entity_organogram: [],
          } as any,
        },
      load: false,
    };
  },
  async created() {
    this.load = true;
    this.entity_type = VueCookieNext.getCookie("_seip_entity_type");
    this.userInfo = VueCookieNext.getCookie("_seip_user");
    await this.getBankInfo();
    await this.getAgentBankInfo();
    await this.getMobileBankInfo();
    this.user_role = this.userInfo?.employee?.entity_organogram[0]?.entitytyperole?.role_title;
    this.emitter.on("bank_info_updated", async (data) => {
      this.formData = data;
      setTimeout(() => (this.load = false), 2000);
    });
  },
  methods: {
    changeType() {
      this.bankType = true;
    },
    async getBankInfo() {
      await ApiService.get("configurations/bank/list")
        .then((response) => {
          this.banks = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAgentBankInfo() {
      await ApiService.get("configurations/bank/list?agent_bank=1")
        .then((response) => {
          this.agent_banks = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getMobileBankInfo() {
      await ApiService.get("configurations/mobile_bank/list")
        .then((response) => {
          this.mobile_banks = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async formSubmit() {

      if(this.formData.account_type == 'Bank Account' && this.formData.bank_routing_no ==''){
         Swal.fire({
            title: "Warning!",
            html: 'Routing no field is required.',
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-warning",
            },
          });
      }else if(this.formData.account_type == 'Mobile Banking' && this.formData.x_mobile_bankings_id == '1' && this.formData.bank_account_number.toString().length != 12){
        Swal.fire({
            title: "Warning!",
            html: 'Rocket account no must be 12 digits.',
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-warning",
            },
          });
      }else{
        let formData = new FormData();
      for (var key in this.formData) {
        if (this.formData[key] && this.formData[key] !== 'null') {
           formData.set(key, this.formData[key]);
        }
      }
      if(this.entity_type == 1004 && this.user_role == 'Admin'){
         formData.set("seip_admin", '1');
      }else{
         formData.set("seip_admin", '0');
      }
      this.loading = true;
      await ApiService.post("trainee/updateBankInfo", formData)
        .then((response) => {
          if (response.status == 200) {
            this.loading = false;
            if (response.data.status == "error") {
              Swal.fire({
                title: "Error!",
                html: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
              });
            } else {
              DrawerComponent?.hideAll();
              Swal.fire({
                title: "Success!",
                text: response.data.data,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              }).then(() => {
                //this.$router.push("/sign-up");
              });
            }
          } else {
            this.loading = false;
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
        });
      }
      


    },
  },
});
